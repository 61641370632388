import React from 'react'
import styled from 'styled-components'
import ScriptLoader from '../../components/ScriptLoader'

const StyledScriptDiv = styled.div`
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 14px;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: 80%;
    margin-bottom: 12px;
  }

  div#script_tag > div + div {
    display: none;
  }
`
const Telmi = () => {
  return (
    <StyledScriptDiv>
      <ScriptLoader
        script={`!function(t,e,n,s,i,w,c,o){t.TelmiWidget=w,t.tw=t.tw||function(){(t.tw.q=t.tw.q||[]).push(arguments)},c=e.createElement(n),o=(o=e.getElementsByTagName(n))[o.length-1],c.async=1,c.src="//"+s+"/resources/dist/widget.min.js",t.tw.s=c,document.getElementById('script_tag').appendChild(c),tw("config","host",s)}(window,document,"script","www.telmi.it",0,"tw");tw('config','lang','de');tw('show-search-form');`}
        id={'script_tag'}
      />
    </StyledScriptDiv>
  )
}

export default Telmi
