import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import isServerSide from '../../modules/is-server-side'

const Wrapper = styled.div``

const ScriptLoader = ({ script, id, ...props }) => {
  const instance = useRef(null)
  useEffect(() => {
    if (!isServerSide()) {
      const scriptTag = document.createElement('script')

      scriptTag.innerHTML = script

      instance.current.appendChild(scriptTag)
    }
  }, [])
  return (
    <>
      <div id={id} ref={instance}></div>
    </>
  )
}
ScriptLoader.propTypes = {}
ScriptLoader.defaultProps = {}
export default ScriptLoader
