import React, { useState } from 'react'
import styled from 'styled-components'
import translate from '../../../../modules/translate'
import ContainerAd from '../../../../components/ContainerAd'
import ErrorBoundary from '../../../Error/widgets/ErrorBoundary'
import MessageError from '../../../Error/screens/MessageError'
import LoadMore from '../../../../components/LoadMore'
import Text from '../../../../components/Text'
import WidgetNearMeWeather from '../../../Weather/widgets/WidgetNearMeWeather'
import Telmi from '../../../../vendor/Telmi'
import Block from '../../../../layouts/Blocks'
import { isLocationInSouthTyrol } from '../../../../modules/location'
import routes from '../../../routes'

const StyledWidgetNearMeWeather = styled(WidgetNearMeWeather)`
  margin-bottom: ${(props) => props.theme.sizes.gapVerticalHalf};
`

const StyledContainerAd = styled(ContainerAd)`
  > div > div {
    margin: 0 10px ${(props) => props.theme.sizes.gapVerticalHalf};
  }
  padding: 0;
  justify-content: space-evenly;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    display: flex;
    align-items: center;
  }
`

const StyledText = styled(Text)`
  margin: 0 auto;
  margin-top: ${(props) => props.theme.sizes.gapVertical};
  margin-bottom: 10vh;
  font-size: ${(props) => props.theme.fontSize.title2};
  margin-left: ${(props) => props.theme.sizes.gapEdge};
  margin-right: ${(props) => props.theme.sizes.gapEdge};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.title2};
    margin-left: ${(props) => props.theme.tablet.sizes.gapEdge};
    margin-right: ${(props) => props.theme.tablet.sizes.gapEdge};
    margin-top: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-left: ${(props) => props.theme.mobile.sizes.gapEdge};
    margin-right: ${(props) => props.theme.mobile.sizes.gapEdge};
    font-size: ${(props) => props.theme.mobile.fontSize.title2};
    margin-top: ${(props) => props.theme.mobile.sizes.gapVertical};
  }
  text-align: center;
`

const ScreenNearMe = ({ failed, latitude, longitude, radius, data, apiRoute, ...props }) => {
  const [helperArticles, setHelperArticles] = useState(data && data.articles)
  const errorOptions = {
    component: translate('nearme_title'),
    link: '/',
    linkText: translate('error_try_homepage'),
    logError: 'NearMe display screen',
  }
  if (longitude === 1)
    return (
      <>
        <StyledText>{translate('nearme_position_not_found')}</StyledText>
        <StyledContainerAd adIds={['S3', 'S4']} />
      </>
    )
  if (!helperArticles) return <MessageError logInfo={translate('dev_errorlog_no_data')} {...errorOptions} />

  return (
    <React.Fragment {...props}>
      <Block view="articles" data={{ articles: helperArticles && helperArticles.slice(0, 3) }} />
      <StyledContainerAd adIds={['S3', 'S4']} />
      <ErrorBoundary error={failed} {...errorOptions}>
        {data && data.place && longitude !== 1 && isLocationInSouthTyrol(latitude, longitude) && (
          <StyledWidgetNearMeWeather data={data.weather} latitude={latitude} longitude={longitude} />
        )}
      </ErrorBoundary>

      <Block view="articles" data={{ articles: helperArticles && helperArticles.slice(3, 6) }} />
      <Telmi />
      <Block view="articles" data={{ articles: helperArticles && helperArticles.slice(6) }} />
      {helperArticles && (
        <LoadMore
          articles={helperArticles}
          loadMoreRoute={routes.generatePathByRouteName('nearMe', { latitude, longitude, radius }, 'api')}
          loadMoreArgument={helperArticles.length}
          afterLoadMore={(newArticles) => setHelperArticles(newArticles)}
        />
      )}
    </React.Fragment>
  )
}

export default ScreenNearMe
